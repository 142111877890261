import React from 'react'

// Components
import { Helmet } from 'react-helmet'
import SubNav from '../components/SubNav'
import ListingItem from '../components/ListingItem'
import TemplateWrapper from '../components/Layout'

import { graphql } from 'gatsby'

const EventsPage = ({ data }) => {
  let eventobjects = []

  if (data.eventobjects) eventobjects = data.eventobjects.edges

  let subNavItems = data.subnav

  return (
    <TemplateWrapper>
      <div>
        <Helmet title="Events | NAVEL" />
        <div>
          <SubNav
            items={subNavItems}
            currentItem="news"
            subNavClass="subnav-for-news-page"
          />

          <div className="article-section-wrap upcoming">
            <div className="article-listing-wrap">
              {eventobjects.map(({ node }) => {
                return <ListingItem key={node.id} node={node} />
              })}

              {eventobjects.length < 1 && (
                <div style={{ textAlign: 'center', width: '100%' }}>
                  Coming soon!
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </TemplateWrapper>
  )
}

export default EventsPage

export const pageQuery = graphql`
  query NewsQuery {
    allMdx(limit: 2000) {
      group(field: frontmatter___events) {
        fieldValue
        totalCount
      }
    }
    eventobjects: allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/news/" } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            subtitle
            date
            customDate
            time
            showOnHomepage
            primaryImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1440, quality: 80) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            teaser
          }
          excerpt
        }
      }
    }
    subnav: allMdx(
      filter: {
        frontmatter: { hideFromSubnav: { ne: "true" } }
        fileAbsolutePath: { regex: "/about/" }
      }
      sort: { fields: [frontmatter___subnavOrder], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`
